<template>
  <div>
    <div class="row my-0">
      <div class="col-12">
        <h1 class="mb-4">{{ SHARED.SupportCenterPage.Incompany.Title }}</h1>
        <p>{{ SHARED.SupportCenterPage.Incompany.Description }}</p>
      </div>
    </div>

    <recess-divider show-line />

    <div class="row">
      <div class="col-12">
        <h5>
          {{ SHARED.SupportCenterPage.Incompany.Information.Block1.Title }}
        </h5>
        <p>
          <b>{{ SHARED.SupportCenterPage.Incompany.Information.Block1.Type1.Title }}</b> = {{
            SHARED.SupportCenterPage.Incompany.Information.Block1.Type1.Description }}
        </p>
        <p>
          <b>{{ SHARED.SupportCenterPage.Incompany.Information.Block1.Type2.Title }}</b> = {{
            SHARED.SupportCenterPage.Incompany.Information.Block1.Type2.Description }}
        </p>

      </div>

      <div class="col-12 mt-5">
        <h5>
          {{ SHARED.SupportCenterPage.Incompany.Information.Block2.Title }}
        </h5>

        <template>
          <recess-table-flex :table-options="setTableOptions" :pagination-options="setPaginationOptions">
            <recess-table-flex-head>
              <recess-table-flex-row>
                <recess-table-flex-head-item v-for="header in headers" :key="header.title"
                  class="justify-content-center">{{ header.title }}</recess-table-flex-head-item>
              </recess-table-flex-row>
            </recess-table-flex-head>
            <recess-table-flex-body>
              <recess-table-flex-row>
                <recess-table-flex-cell>{{ SHARED.SupportCenterPage.Incompany.Information.Block2.Table.Row1
                  }}</recess-table-flex-cell>
                <recess-table-flex-cell class="text-center">{{
                  SHARED.SupportCenterPage.Incompany.Information.Block2.Table.Options.Yes }}</recess-table-flex-cell>
                <recess-table-flex-cell />
              </recess-table-flex-row>
              <recess-table-flex-row>
                <recess-table-flex-cell>
                  {{ SHARED.SupportCenterPage.Incompany.Information.Block2.Table.Row2.Part1 }}
                  <span class="u-text-underline">{{
                    SHARED.SupportCenterPage.Incompany.Information.Block2.Table.Row2.Part2 }}</span>
                  {{ SHARED.SupportCenterPage.Incompany.Information.Block2.Table.Row2.Part3 }}
                </recess-table-flex-cell>
                <recess-table-flex-cell />
                <recess-table-flex-cell class="text-center">{{
                  SHARED.SupportCenterPage.Incompany.Information.Block2.Table.Options.Yes }}</recess-table-flex-cell>
              </recess-table-flex-row>
              <recess-table-flex-row>
                <recess-table-flex-cell>{{ SHARED.SupportCenterPage.Incompany.Information.Block2.Table.Row3
                  }}</recess-table-flex-cell>
                <recess-table-flex-cell />
                <recess-table-flex-cell class="text-center">{{
                  SHARED.SupportCenterPage.Incompany.Information.Block2.Table.Options.Yes }}</recess-table-flex-cell>
              </recess-table-flex-row>
              <recess-table-flex-row>
                <recess-table-flex-cell>{{ SHARED.SupportCenterPage.Incompany.Information.Block2.Table.Row4
                  }}</recess-table-flex-cell>
                <recess-table-flex-cell class="text-center">{{
                  SHARED.SupportCenterPage.Incompany.Information.Block2.Table.Options.Yes }}</recess-table-flex-cell>
                <recess-table-flex-cell class="text-center">{{
                  SHARED.SupportCenterPage.Incompany.Information.Block2.Table.Options.Yes }}</recess-table-flex-cell>
              </recess-table-flex-row>
              <recess-table-flex-row>
                <recess-table-flex-cell>{{ SHARED.SupportCenterPage.Incompany.Information.Block2.Table.Row5
                  }}</recess-table-flex-cell>
                <recess-table-flex-cell />
                <recess-table-flex-cell class="text-center">{{
                  SHARED.SupportCenterPage.Incompany.Information.Block2.Table.Options.Optional
                }}</recess-table-flex-cell>
              </recess-table-flex-row>
            </recess-table-flex-body>
          </recess-table-flex>
        </template>
      </div>

      <div class="col-12 mt-5">
        <h5>
          {{ SHARED.SupportCenterPage.Incompany.Information.Block3.Title }}
        </h5>

        <ul class="u-list-decimal mt-4">
          <li>
            {{
              SHARED.SupportCenterPage.Incompany.Information.Block3.Paragraph1
            }}
          </li>
          <li>
            {{
              SHARED.SupportCenterPage.Incompany.Information.Block3.Paragraph2
            }}
          </li>
          <li>
            {{
              SHARED.SupportCenterPage.Incompany.Information.Block3.Paragraph3
            }}
          </li>
          <li>
            {{
              SHARED.SupportCenterPage.Incompany.Information.Block3.Paragraph4
            }}
          </li>
        </ul>
      </div>

      <div class="col-12 mt-5">
        <h5>
          {{ SHARED.SupportCenterPage.Incompany.Information.Block4.Title }}
        </h5>
        <p>
          {{ SHARED.SupportCenterPage.Incompany.Information.Block4.Content }}
        </p>
      </div>

      <div class="col-12 mt-5">
        <h5>
          {{ SHARED.SupportCenterPage.Incompany.Information.Block5.Title }}
        </h5>
        <p>
          {{ SHARED.SupportCenterPage.Incompany.Information.Block5.Content }}
        </p>
      </div>

      <div class="col-12 mt-5">
        <h5>
          {{ SHARED.SupportCenterPage.Incompany.Information.Block6.Title }}
        </h5>
        <p>
          {{ SHARED.SupportCenterPage.Incompany.Information.Block6.Content }}
        </p>
      </div>

      <div class="col-12 mt-5">
        <h5>
          {{ SHARED.SupportCenterPage.Incompany.Information.Block7.Title }}
        </h5>
        <p>
          {{ SHARED.SupportCenterPage.Incompany.Information.Block7.Content }}
        </p>
      </div>

      <div class="col-12 mt-5">
        <h5>
          {{ SHARED.SupportCenterPage.Incompany.Information.Block8.Title }}
        </h5>
        <p>
          {{ SHARED.SupportCenterPage.Incompany.Information.Block8.Part1 }} <b>{{
            SHARED.SupportCenterPage.Incompany.Information.Block8.Part2 }}</b> {{
              SHARED.SupportCenterPage.Incompany.Information.Block8.Part3 }}
        </p>
      </div>

      <div class="col-12 mt-5">
        <h5>
          {{ SHARED.SupportCenterPage.Incompany.Information.Block9.Title }}
        </h5>
        <p>
          {{ SHARED.SupportCenterPage.Incompany.Information.Block9.Content }}
        </p>
      </div>

      <div class="col-12 mt-5">
        <h5>
          {{ SHARED.SupportCenterPage.Incompany.Information.Block10.Title }}
        </h5>

        <ul class="u-list-decimal mt-4">
          <li>
            {{
              SHARED.SupportCenterPage.Incompany.Information.Block10.Paragraph1
            }}
          </li>
          <li>
            {{
              SHARED.SupportCenterPage.Incompany.Information.Block10.Paragraph2
            }}
          </li>
          <li>
            {{
              SHARED.SupportCenterPage.Incompany.Information.Block10.Paragraph3
            }}
          </li>
          <li>
            {{
              SHARED.SupportCenterPage.Incompany.Information.Block10.Paragraph4
            }}
          </li>
        </ul>
      </div>

      <div class="col-12 mt-5">
        <h5>
          {{ SHARED.SupportCenterPage.Incompany.Information.Block11.Title }}
        </h5>
        <p>
          {{ SHARED.SupportCenterPage.Incompany.Information.Block11.Content }}
        </p>
      </div>

    </div>
  </div>
</template>

<script>
import { SHARED } from "../../constants/EdumsSharedConstants.json";

export default {
  Name: "Incompany",
  data() {
    return {
      SHARED,
      setTableOptions: {
        setCellSizes: true
      },
      setPaginationOptions: {
        showTopPagination: false,
        showBottomPagination: false,
      },
      headers: [
        { title: '' },
        { title: SHARED.SupportCenterPage.Incompany.Information.Block2.Table.Headers.OpenLine },
        { title: SHARED.SupportCenterPage.Incompany.Information.Block2.Table.Headers.Incompany }
      ]
    }
  }
}
</script>
